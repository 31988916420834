var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ny_news"},[_c('div',{staticClass:"ny_news_bg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.basicData.msg_bg,"alt":""}})]),_c('div',{staticClass:"ny_news_nav"},[_c('div',{staticClass:"nav_col"},[_c('ul',{staticClass:"nav_col_cp"},[(Object.keys(_vm.basicData).length != 0)?_c('h2',{style:({
            background: `url(${_vm.alData.kh_img}) 100% no-repeat`
          })},[_vm._v(" "+_vm._s(_vm.basicData.msg_title[0].value)+" "),_c('b',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.basicData.msg_title[0].key))])]):_vm._e(),_vm._l((_vm.cpList),function(item,idx){return _c('li',{key:idx,class:[_vm.currentIdx == idx ? 'xz_li' : '']},[_c('a',{attrs:{"href":'#/ny_news?currentIdx=' + _vm.currentIdx},on:{"click":function($event){return _vm.switchList(item, idx)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])})],2),(Object.keys(_vm.basicData).length != 0)?_c('ul',{staticClass:"nav_col_cp",staticStyle:{"margin-top":"0.5rem"}},[_c('h2',{style:({
            background: `url(${_vm.alData.kh_img}) 100% no-repeat`
          })},[_vm._v(" "+_vm._s(_vm.basicData.msg_title[1].value)+" "),_c('b',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.basicData.msg_title[1].key))])]),_c('div',{staticClass:"cp_tit"},[_c('img',{attrs:{"src":_vm.basicData.lx_icon,"alt":""}}),_c('h3',[_vm._v(_vm._s(_vm.basicData.msg_title[2].key))]),_c('h4',[_vm._v(_vm._s(_vm.basicData.msg_title[2].value))])]),_vm._l((_vm.basicData.lx_list),function(item,idx){return _c('li',{key:idx},[_c('p',[_vm._v(_vm._s(item.key)+_vm._s(item.value))])])})],2):_vm._e()]),_c('div',{staticClass:"nav_list"},[_c('div',{staticClass:"nav_list_head"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('span',{staticClass:"el-breadcrumb__item",staticStyle:{"margin-right":"8px"}},[_c('i',{staticClass:"el-icon-s-home"}),_vm._v(" 当前位置：")]),_vm._l((_vm.alData.kh_head),function(item,idx){return _c('el-breadcrumb-item',{key:idx,attrs:{"to":{ path: item.value }},on:{"click":function($event){return _vm.allProduct(1, '', _vm.project)}}},[_vm._v(" "+_vm._s(item.key))])})],2)],1),(!_vm.currentId)?_c('div',{staticClass:"nav_list_col"},[(_vm.product.length != 0)?_c('ul',{staticClass:"item"},[_vm._l((_vm.product),function(item,idx){return _c('li',{key:idx,on:{"click":function($event){return _vm.goDetails(item)}}},[_c('div',{staticClass:"list_nav"},[_c('div',{staticClass:"list_nav_date"},[_c('span',{staticClass:"date_span"},[_vm._v(_vm._s(item.create_time.slice(8, 10)))]),_c('span',[_vm._v(_vm._s(item.create_time.slice(0, 7)))])]),_c('div',{staticClass:"list_nav_text"},[_c('a',{staticClass:"ellipsis",attrs:{"href":'#/ny_news?currentIdx=' +
                      _vm.currentIdx +
                      '&currentId=' +
                      item.id}},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"two_ellipsis"},[_vm._v(_vm._s(item.miaoshu))])]),_c('a',{staticClass:"list_nav_link",attrs:{"href":'#/ny_news?currentIdx=' +
                    _vm.currentIdx +
                    '&currentId=' +
                    item.id}},[_vm._v("查看详情")])])])}),_c('onLoading',{attrs:{"show":_vm.loading}})],2):_c('div',{staticClass:"details_empty"},[_c('img',{staticStyle:{"margin":"0 auto"},attrs:{"src":require("@/assets/image/xwdt/xwdt-data.webp"),"alt":""}})]),_c('div',{staticClass:"all-pagination"},[(_vm.total > 8)?_c('el-pagination',{attrs:{"prev-text":"上一页","next-text":"下一页","current-page":_vm.page,"background":"","page-size":8,"layout":"prev, pager, next,total","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_c('span',{staticClass:"no_meet_conditions"},[_vm._v("暂无更多数据")])],1)]):_c('div',{staticClass:"nav_list_html",domProps:{"innerHTML":_vm._s(_vm.sjData.maincontent)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }