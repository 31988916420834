<template>
  <div class="ny_news">
    <div class="ny_news_bg">
      <img :src="basicData.msg_bg" alt="" style="width:100%" />
    </div>
    <div class="ny_news_nav">
      <div class="nav_col">
        <ul class="nav_col_cp">
          <h2
            v-if="Object.keys(basicData).length != 0"
            :style="{
              background: `url(${alData.kh_img}) 100% no-repeat`
            }"
          >
            {{ basicData.msg_title[0].value }}
            <b style="display:block">{{ basicData.msg_title[0].key }}</b>
          </h2>
          <li
            v-for="(item, idx) in cpList"
            :key="idx"
            :class="[currentIdx == idx ? 'xz_li' : '']"
          >
            <a
              :href="'#/ny_news?currentIdx=' + currentIdx"
              @click="switchList(item, idx)"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
        <ul
          class="nav_col_cp"
          style="margin-top:0.5rem"
          v-if="Object.keys(basicData).length != 0"
        >
          <h2
            :style="{
              background: `url(${alData.kh_img}) 100% no-repeat`
            }"
          >
            {{ basicData.msg_title[1].value }}
            <b style="display:block">{{ basicData.msg_title[1].key }}</b>
          </h2>
          <div class="cp_tit">
            <img :src="basicData.lx_icon" alt="" />
            <h3>{{ basicData.msg_title[2].key }}</h3>
            <h4>{{ basicData.msg_title[2].value }}</h4>
          </div>
          <li v-for="(item, idx) in basicData.lx_list" :key="idx">
            <p>{{ item.key }}{{ item.value }}</p>
          </li>
        </ul>
      </div>
      <div class="nav_list">
        <div class="nav_list_head">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span class="el-breadcrumb__item" style="margin-right:8px"
              ><i class="el-icon-s-home"></i> 当前位置：</span
            >
            <el-breadcrumb-item
              v-for="(item, idx) in alData.kh_head"
              :key="idx"
              :to="{ path: item.value }"
              @click="allProduct(1, '', project)"
            >
              {{ item.key }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="nav_list_col" v-if="!currentId">
          <ul class="item" v-if="product.length != 0">
            <li
              v-for="(item, idx) in product"
              :key="idx"
              @click="goDetails(item)"
            >
              <!-- ellipsis -->
              <div class="list_nav">
                <div class="list_nav_date">
                  <span class="date_span">{{
                    item.create_time.slice(8, 10)
                  }}</span>
                  <span>{{ item.create_time.slice(0, 7) }}</span>
                </div>
                <div class="list_nav_text">
                  <a
                    :href="
                      '#/ny_news?currentIdx=' +
                        currentIdx +
                        '&currentId=' +
                        item.id
                    "
                    class="ellipsis"
                    >{{ item.title }}</a
                  >
                  <p class="two_ellipsis">{{ item.miaoshu }}</p>
                </div>
                <a
                  class="list_nav_link"
                  :href="
                    '#/ny_news?currentIdx=' +
                      currentIdx +
                      '&currentId=' +
                      item.id
                  "
                  >查看详情</a
                >
              </div>
            </li>
            <onLoading :show="loading"></onLoading>
          </ul>
          <div class="details_empty" v-else>
            <img
              src="@/assets/image/xwdt/xwdt-data.webp"
              alt=""
              style="margin:0 auto"
            />
          </div>
          <div class="all-pagination">
            <el-pagination
              prev-text="上一页"
              next-text="下一页"
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              background
              :page-size="8"
              layout="prev, pager, next,total"
              :total="total"
              v-if="total > 8"
            >
            </el-pagination>
            <span class="no_meet_conditions" v-else>暂无更多数据</span>
          </div>
        </div>
        <div class="nav_list_html" v-else v-html="sjData.maincontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getXwTypeData, getAllXwData, getXwxqData, getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      basicData: {},
      cpList: [],
      currentId: 0,
      currentIdx: 0,
      alData: {},
      product: [],
      loading: false,
      total: 0,
      sjData: {},
      page: 1,
      project: '',
      type_id: ''
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.currentIdx = this.$route.query.currentIdx
        } else {
          this.currentIdx = 0
          this.type_id = ''
          this.allProduct(1, '', this.project)
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (that.$route.query.currentIdx) {
        that.currentIdx = that.$route.query.currentIdx
      } else {
        that.currentIdx = 0
      }
      if (that.$route.query.currentId) {
        that.currentId = that.$route.query.currentId
        that.goDetails({ id: that.currentId })
      } else {
        that.currentId = 0
      }
      let id = sessionStorage.getItem(that.changeData() + 'id')
      let project = sessionStorage.getItem(this.changeData() + 'project')
      that.project = project
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.basicData = res.data.data.基本信息
          that.alData = res.data.data.客户
          getXwTypeData(1, project).then(res => {
            if (res.data.code == 1) {
              res.data.data.unshift({
                name: '全部动态',
                id: ''
              })
              that.cpList = res.data.data
              if (that.alData.kh_head != 0) {
                that.alData.kh_head.push({
                  key: that.cpList[that.currentIdx].name
                })
              }
            }
          })
        }
      })
      that.allProduct(1, '', that.project)
    },
    switchList (e, i) {
      this.currentIdx = i
      this.currentId = 0
      this.allProduct(1, e.id, this.project)
      if (this.alData.kh_head != 0) {
        this.alData.kh_head[2].key = e.name
      }
    },
    allProduct (i, x, d) {
      this.loading = true
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.product = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    goDetails (e) {
      this.currentId = e.id
      this.sjData = {}
      getXwxqData(e.id).then(res => {
        if (res.data.code == 1) {
          this.sjData = res.data.data
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.allProduct(val, this.type_id, this.project)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ny_news {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .ny_news_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .ny_news_nav {
    margin: 1rem auto;
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    .nav_col {
      width: 226px;
      h2 {
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 20px;
        padding: 12px 0 25px;
        font-weight: normal;
        line-height: 1.4;
        margin-bottom: 0.1rem;
        width: 100%;
      }
      .nav_col_cp {
        padding-bottom: 0.1rem;
        border-width: 3px 1px 1px;
        border-style: solid;
        border-color: #3da136;
        box-sizing: border-box;
        font-size: 14px;
        background-color: #fff;
        .cp_tit {
          text-align: center;
          padding-bottom: 0.2rem;
          margin-bottom: 0.1rem;
          border-bottom: 1px solid #ddd;
          box-sizing: border-box;
          img {
            margin-top: 0.1rem;
          }
          h3 {
            margin: 0.15rem 0;
          }
          h4 {
            color: rgb(61, 160, 54);
            font-size: 0.25rem;
            font-weight: 600;
          }
        }
        li {
          text-align: center;
          line-height: 2.5;
          padding: 0 0.2rem;
          p {
            text-align: left;
          }
        }
        .xz_li {
          color: #3da136;
          font-weight: 600;
        }
      }
    }
    .nav_list {
      width: calc(100% - 250px);
      margin-left: 24px;
      .nav_list_head {
        padding: 0.15rem 0;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
      }
      .nav_list_col {
        margin-top: 0.3rem;
        .item,
        .details_empty {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          position: relative;
          li {
            display: inline-block;
            font-size: 0.18rem;
            vertical-align: middle;
            width: calc(100% - 0.6rem);
            margin-bottom: 20px;
            padding: 0.3rem;
            background-color: #fff;
            position: relative;
            overflow: hidden;
            .list_nav {
              display: flex;
              flex-wrap: wrap;
              position: relative;
              .list_nav_date {
                color: #555555;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                .date_span {
                  font-size: 0.4rem;
                  font-weight: 600;
                  margin-bottom: 0.05rem;
                }
              }
              .list_nav_text {
                margin-left: 0.3rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 0.16rem;
                a {
                  font-weight: 600;
                  font-size: 0.18rem;
                }
                h2:hover {
                  color: #3da036;
                }
              }
              .list_nav_link {
                position: absolute;
                right: 0;
                padding: 6px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #999;
                color: #fff;
                font-size: 14px;
              }
              .list_nav_link:hover {
                background-color: #3da036;
              }
            }
          }
        }
        .details_empty {
          background-color: #fff;
        }
      }
      .nav_list_html {
        margin-top: 0.3rem;
        line-height: 1.5;
      }
    }
  }
}
</style>
